import React from "react";

const features = [
  {
    id: 1,
    title: "Envía tu Solicitud",
  },
  {
    id: 2,
    title: "Sube tu Logo",
  },
  {
    id: 3,
    title: "Elige la Plantilla para el Diseño de tu Tienda",
  },
  {
    id: 4,
    title: "Carga tus productos manual o masivamente",
  },
  {
    id: 5,
    title: "Configura las formas de pago",
  },
  {
    id: 6,
    title: "Configura los métodos de envío",
  },
  {
    id: 7,
    title:
      "Activa tu tienda en línea y acepta pagos con Visa, MasterCard y Clave Online.",
  },
  {
    id: 8,
    title: "Empieza a Vender",
  },
];

const Create_online_store = () => {
  return (
    <>
      <section className="bg-pirGray ">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="max-w-lg mx-auto text-center mb-16">
            <h2 class="text-3xl  sm:text-3xl font-avenird text-[#313C42]">
              ¿Cómo crear tu tienda online?
            </h2>
          </div>
          <div className="grid lg:gap-20 md:gap-20 gap-8  mx-auto lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-4 grid-cols-3 ">
            {features.map((feature) => (
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-14 h-14 mb-4  text-lg font-bold  rounded-full bg-pirOrange">
                  <span className="text-pirWhite">{feature.id}</span>
                </div>

                <div className="flex flex-col items-center">
                  <p className="text-[14px] lg:text-[20px] md:text-lg font-avenird text-center text-[#313C42]">
                    {feature.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Create_online_store;
