import React from "react";

const Rates = () => {
  return (
    <>
      <section id="pricing" className="bg-[#148040] -mt-12">
        <div className="px-4  py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32 bg-[url('https://img.invupos.com/quicke/Rates%20/rates%20shape.png')] bg-[length:500px] bg-[mr-16]  lg:bg-[right_bottom_-5rem] bg-[right_bottom_10rem] bg-no-repeat ">
          <h2 className="text-4xl font-avenirm text-left tracking-tight mb-10 lg:text-[49px] md:text-[49px] text-pirWhite ">
            Tarifas
          </h2>
          <div className="p-4 bg-pirWhite rounded-[30px] shadow-xl sm:p-10">
            <div className="flex  justify-between pt-5 pb-6 sm:flex-row">
              <div>
                <p className="lg:text-2xl lg:max-w-2xl text-[#313C42] font-semibold text-left">
                  Tasa sobre el volumen mensual de ventas y reembolsos
                </p>
                <p className="text-left text-[#313C42] mt-2 text-[15px] max-w-[250px] lg:max-w-md">
                  Incluye Clave Online y acceso al Portal de Link de pagos
                </p>
              </div>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                <p className="text-xl font-semibold text-[#313C42]">2.95%</p>
              </div>
            </div>
            <div className="border border-pirGray"></div>

            <div className="flex justify-between pt-5 pb-6 sm:flex-row">
              <div>
                <p className="lg:text-2xl lg:max-w-xl max-w-[250px] text-[#313C42] font-semibold text-left ">
                  Cargo por transacciones aprobadas y/o reembolsadas{" "}
                  <span className="font-light">(incluye 3D Secure)</span>
                </p>
              </div>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                <p className="text-xl font-semibold text-[#313C42]">$0.40</p>
              </div>
            </div>
            <div className="border border-pirGray"></div>

            <div className="flex  justify-between pt-5 pb-6 sm:flex-row">
              <div>
                <p className="lg:text-2xl lg:max-w-xl max-w-[250px] text-[#313C42] font-semibold text-left ">
                  Contracargos{" "}
                  <span className="font-light">(Chargebacks o Disputas)</span>
                </p>
              </div>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                <p className="text-xl font-semibold text-[#313C42]">$35.00</p>
              </div>
            </div>
            <div className="border border-pirGray"></div>

            <div className="flex  justify-between pt-5 pb-6 sm:flex-row">
              <div>
                <p className="lg:text-2xl lg:max-w-xl max-w-[200px] text-[#313C42] font-semibold text-left ">
                  Mensualidad
                </p>
              </div>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                <p className="text-xl font-semibold text-[#313C42]">$35.00</p>
              </div>
            </div>
            <div className="border border-pirGray"></div>

            <div className="flex  justify-between pt-5 pb-6 sm:flex-row">
              <div>
                <p className="lg:text-2xl lg:max-w-xl max-w-[200px] text-[#313C42] font-semibold text-left ">
                  Pago único por integración
                </p>
              </div>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                <p className="text-xl font-semibold text-[#313C42]">$100.00</p>
              </div>
            </div>
            <div className="border border-pirGray"></div>

            <div className="flex  justify-between pt-5 pb-6 sm:flex-row">
              <div>
                <p className="lg:text-2xl lg:max-w-[390px] max-w-[250px] text-[#313C42] font-semibold text-left ">
                  Cargo por transacción Internacional{" "}
                  <span className="font-light">(en dolares $$)</span>
                </p>
              </div>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                <p className="text-xl font-semibold text-[#313C42]">0.65%</p>
              </div>
            </div>
            <div className="border border-pirGray"></div>

            <div className="flex  justify-between pt-5 pb-6 sm:flex-row">
              <div>
                <p className="lg:text-2xl lg:max-w-xl max-w-[200px] text-[#313C42] font-semibold text-left">
                  Uso de Yappy
                </p>
              </div>
              <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                <p className="text-xl font-semibold text-[#313C42]">$1.00</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rates;
