import React, { useState } from "react";
import Scroll from "react-scroll";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <section className="bg-pirGreen">
        <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div class="relative flex items-center justify-between">
            <a
              href="/"
              aria-label="Company"
              title="Company"
              class="inline-flex items-center"
            >
              <img
                className="w-44 sm:52 md:w-52 lg:w-[250px]"
                alt="logo-quick-e"
                src="https://img.invupos.com/quicke/Navbar/Logo%20Quicke.svg"
              />
            </a>
            <ul class="flex items-center hidden space-x-12 lg:flex">
              <li>
                <Link
                  to="pricing"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                >
                  <a
                    aria-label="Pricing"
                    title="Pricing"
                    class="font-medium tracking-wide cursor-pointer font-semibold text-[19px] text-pirWhite transition-colors duration-200 hover:text-deep-purple-accent-400"
                  >
                    Tarifas
                  </a>
                </Link>
              </li>
              <li>
                <a
                  class="inline-flex items-center font-semibold text-[19px] justify-center h-14 px-6 font-medium hover:bg-[#4E9B4E]  tracking-wide text-pirWhite transition duration-200 rounded-full border-2 border-pirWhite shadow-md bg-transparent hover:bg-deep-purple-accent-700  focus:outline-none"
                  aria-label="Log In"
                  title="Log In"
                  href="https://dashboard.quicke.com.pa/dashboard/index.php/auth/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Log In
                </a>
              </li>
            </ul>
            <div class="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg class="w-5 text-pirWhite" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div class="absolute top-0 left-0 w-full">
                  <div class="bg-[#338436] p-5  rounded-lg shadow-sm">
                    <div class="flex items-center justify-between mb-4">
                      <div>
                        <a
                          href="/"
                          aria-label="Company"
                          title="Company"
                          class="inline-flex items-center"
                        >
                          <img
                            className="w-40"
                            alt="logo-quick-e"
                            src="https://img.invupos.com/quicke/Navbar/Logo%20Quicke.svg"
                          />
                        </a>
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg class="w-5 text-pirWhite" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul class="space-y-4">
                        <li>
                          <Link
                            to="pricing"
                            activeClass="active"
                            spy={true}
                            smooth={true}
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <a
                              aria-label="Pricing"
                              title="Pricing"
                              class="font-medium tracking-wide text-pirWhite transition-colors duration-200 hover:text-deep-purple-accent-400"
                            >
                              Tarifas
                            </a>
                          </Link>
                        </li>
                        <li>
                          <a
                            class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-pirWhite transition duration-200 rounded-full border shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            aria-label="Log In"
                            title="Log In"
                            onClick={() => setIsMenuOpen(false)}
                            href="https://dashboard.quicke.com.pa/dashboard/index.php/auth/login"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Log In
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
