import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <section className="bg-pirGreen lg:rounded-b-[70px] rounded-b-[40px]  -mt-10">
        <div className="px-4  text-left py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
          <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
            <div className="flex flex-col justify-center">
              <div className="max-w-xl mb-6">
                <div>
                  <h1 className="max-w-2xl lg:mt-0 md:mt-12 mt-6 mb-6 font-avenirm text-5xl tracking-tight text-pirWhite sm:text-[70px] sm:leading-none">
                    <span className="font-cantata">¡</span>
                    Tu tienda en línea rápida y segura
                    <span className="font-cantata">!</span>
                  </h1>
                </div>
                <p className="text-base font-avenirr font-medium text-gray-700 md:text-lg uppercase text-pirWhite">
                  Dale un impulso a tu negocio
                </p>
              </div>
              <form>
                <div class="flex items-center text-center lg:space-x-3  md:space-x-3 space-x-1">
                  <Link
                    to="/contact-form"
                    onClick={() => window.scrollTo(0, 0)}
                    class="lg:w-40 md:w-40 w-40 inline-block px-5 py-5 font-bold text-lg  text-pirWhite bg-pirOrange hover:bg-[#ED811A] rounded-full"
                  >
                    Empieza ya!
                  </Link>
                </div>
              </form>
              <p class="text-[15px] font-myriadr text-pirWhite mt-8 max-w-lg">
                *Empieza Ya! Llena el formulario de registro y prueba Quick-e
                gratis por 14 días.*
              </p>
            </div>
            <div>
              <img
                className="object-center object-cover h-[415px] lg:h-[590px] md:h-[610px] w-full rounded-md"
                src="https://img.invupos.com/quicke/Hero%20/Mockup%20app%20quicke.png"
                alt="Mockup mobile app Quick-e"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
