import React from "react";
import { Link } from "react-router-dom";

const Card_cta = () => {
  return (
    <>
      <section className="">
        <div class="max-w-screen-xl mx-auto sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:mt-32 md:mt-32 mt-28 lg:gap-16 lg:grid-cols-2 bg-[#34B24A] lg:p-12 md:p-12 p-6 lg:rounded-[50px] md:rounded-[50px] sm:rounded-[50px]  bg-[url('')] bg-[length:450px] bg-[mr-16] bg-[left_top_4rem] bg-no-repeat">
            <div class="relative h-[345px] lg:mt-0 md:mt-0 sm:mt-0 mt-8 overflow-hidden   sm:h-[600px] lg:h-[472px] ">
              <img
                class="absolute inset-0 mix-blend-darken"
                src="https://img.invupos.com/quicke/Card%20Credicorp%20Bank%20debit/TARJETA_VISA_CREDICORP_1_shadow.png"
                // src="https://res.cloudinary.com/dslkvd23s/image/upload/v1658862045/Quick-e/Free_Credit_Card_Mockup_2_sombra_1_5_z3bofw.png"
                alt="Card Shadow"
              />
              <img
                class="absolute inset-0"
                src="https://img.invupos.com/quicke/Card%20Credicorp%20Bank%20debit/TARJETA_VISA_CREDICORP_WITHOUT_SHADOW_1.png"
                // src="https://res.cloudinary.com/dslkvd23s/image/upload/v1658861912/Quick-e/Free_Credit_Card_Mockup_2_sombra_1_4_w3cuyz.png"
                alt="Credit card Credicorp Bank "
              />
            </div>

            <div class="lg:py-6 -mt-12 md:mt-8">
              <div class="text-left text-gray-600">
                <h2 className="max-w-lg mb-6 mt-6 font-avenirr lg:text-left md:text-left sm:text-left text-center font-bold text-4xl tracking-tight text-pirWhite sm:text-[50px] sm:leading-none">
                  Recibe el dinero de tus ventas en tarjeta de débito o cuenta
                  comercial.
                </h2>
                <p className="text-[20px] lg:text-[24px] md:text-[22px] sm:max-w-lg mt-8 font-myriadr text-center lg:text-left md:text-left sm:text-left text-gray-700 text-[#313C42]">
                  Obtén tus ganancias de forma rápida y segura a través de tu
                  tarjeta de débito
                </p>

                <div className="text-center lg:text-left md:text-left sm:text-left">
                  <Link
                    to="/contact-form"
                    onClick={() => window.scrollTo(0, 0)}
                    class="inline-block px-8 mt-8 mb-1 py-5 text-sm  hover:bg-[#ED811A] font-medium text-white bg-pirOrange text-pirWhite font-semibold  rounded-full active:text-indigo-500 hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring"
                  >
                    Empieza aquí!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Card_cta;
