import React from "react";

const products = [
  {
    id: 1,
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Templates%20quicke/Template%201.png",
    imageAlt: "Plantilla 1 - Quick-e",
  },
  {
    id: 2,
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Templates%20quicke/Template%202.png",
    imageAlt: "Plantilla 2 - Quick-e",
  },
  {
    id: 3,
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Templates%20quicke/Template%203.png",
    imageAlt: "Plantilla 3 - Quick-e",
  },
  {
    id: 4,
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Templates%20quicke/Template%204.png",
    imageAlt: "Plantilla 4 - Quick-e",
  },
];

const Online_business = () => {
  return (
    <>
      <div className="bg-[#EBF7ED]">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 overflow-hidden  bg-[url('https://img.invupos.com/quicke/Templates%20quicke/shape/shape%20templates.png')] bg-[length:250px]  bg-[right_top_-10rem] bg-no-repeat ">
          <h2 className="text-3xl  sm:text-3xl font-avenird text-center text-[#313C42]">
            Todo lo que necesitas para vender por internet.
          </h2>
          <p className="text-center mt-2 font-avenird text-3xl  sm:text-3xl text-[#3B823E]">
            Plantillas faciles de{" "}
            <span className="lg:text-[#3B823E] md:text-[#3B823E] text-[#34B24A]">
              usar
            </span>
          </p>
          <div className="mt-12 grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 ">
            {products.map((product) => (
              <div key={product.id} className="group relative">
                <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden lg:h-[460px] lg:aspect-none">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    loading="lazy"
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Online_business;
