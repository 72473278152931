import React from "react";

import Card_cta from "../components/Card_cta";
import Create_online_store from "../components/Create_online_store";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Online_business from "../components/Online_business";
import Rates from "../components/Rates";

const Landing = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Online_business />
      <Create_online_store />
      <Card_cta />
      <Features />
      <Rates />
      <Footer />
    </>
  );
};

export default Landing;
