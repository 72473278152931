import "./App.css";
import Form_contact from "./pages/Form_contact";
import Landing from "./pages/Landing";
import { Route, Routes, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact-form" element={<Form_contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
