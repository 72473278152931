import React from "react";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <>
      <section className="bg-[#148040]">
        <footer class="bg-pirFooter py-16 lg:rounded-t-[70px] rounded-t-[40px]">
          <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div class="flex -mt-12 justify-center">
              <div>
                <img
                  src="https://img.invupos.com/quicke/Logo_clau_new1.svg"
                  alt="Credicorp Bank logo"
                  class="block object-contain h-[69px]"
                />
              </div>
              <div class="divider mx-4 border-[2.5px] border-l border-pirWhite"></div>

              <div>
                <img
                  src="https://res.cloudinary.com/dslkvd23s/image/upload/v1658877359/Quick-e/Frame_1_1_hgdbdz.svg"
                  alt="Quick-e logo"
                  class="block object-contain h-16"
                />
              </div>
              {/* <img
                className="lg:h-32"
                src="https://img.invupos.com/quicke/Footer/Logo%20Credicorp%20Bank%20Quicke.png"
              /> */}
            </div>
            <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-3 gap-x-40 mt-20">
              <div className=" text-left">
                <div className="lg:max-w-sm">
                  <p class="mt-4 text-left text-pirTextGreen lg:text-left lg:text-lg">
                    Centro de Relaciones con los Clientes Contáctanos cuando lo
                    necesites, para lo que necesites.
                  </p>
                  <p className="mt-6 text-left text-pirTextGreen lg:text-left lg:text-lg">
                    Línea de atención 210-8255
                  </p>
                </div>
              </div>
              <div className="text-left">
                <p className="mt-4 text-pirTextGreen lg:text-lg">Email:</p>
                <div className="flex">
                  <a
                    href="mailto:esales@credicorpbank.com"
                    aria-label="Our email"
                    title="Our email"
                    className="text-pirTextGreen lg:text-lg"
                  >
                    esales@credicorpbank.com
                  </a>
                </div>
                <div className="flex">
                  <a
                    href="mailto:essuport@credicorpbank.com"
                    aria-label="Our email"
                    title="Our email"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-pirTextGreen lg:text-lg"
                  >
                    essuport@credicorpbank.com
                  </a>
                </div>
                <p className="mt-6 text-pirTextGreen lg:text-lg">Legales:</p>
                <div className="flex">
                  <a
                    href="https://cdn.clau.io/terms/quickie/politicas-quickie.pdf"
                    target="_blank"
                    aria-label="Our email"
                    title="Our email"
                    className="text-pirTextGreen lg:text-lg"
                  >
                    Política de Privacidad y de Cookies
                  </a>
                </div>
              </div>
              <div className="text-left">
                <p className="mt-4 text-pirTextGreen lg:text-lg">Legales:</p>
                <div className="flex">
                  <a
                    href="https://drive.google.com/file/d/1OP1nbAfEm_K0ONqwErNNegSkyV2rfRWf/view?usp=sharing"
                    target="_blank"
                    aria-label="Manual de uso"
                    title="Manual de uso"
                    className="text-pirTextGreen lg:text-lg"
                  >
                    Manual de Uso
                  </a>
                </div>
                <div className="flex">
                  <Link
                    to="pricing"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    className="text-pirTextGreen lg:text-lg cursor-pointer"
                  >
                    Tarifas
                  </Link>
                </div>
                <div className="flex">
                  <a
                    href="https://www.credicorpbank.com/producto/protege-las-ventas-de-tu-negocio-online/"
                    target="_blank"
                    aria-label="Recomendaciones de seguridad"
                    title="Recomendaciones de seguridad"
                    rel="noopener noreferrer"
                    className="text-pirTextGreen lg:text-lg"
                  >
                    Recomendaciones de Seguridad
                  </a>
                </div>
                <div className="flex mt-4">
                  <a
                    href="https://cdn.clau.io/terms/quickie/TerminosdeUso-quickie.pdf"
                    target="_blank"
                    aria-label="Terms of service"
                    title="Terms of service"
                    className="text-pirTextGreen lg:text-lg mt-2"
                  >
                    Terms of Service Privacy Policy
                  </a>
                </div>
              </div>
            </div>
            <div className="place-items-center lg:place-items-start md:place-items-start sm:place-items-center grid pt-5 pb-10 mt-10 ">
              <img
                className="w-[200px]"
                src="https://img.invupos.com/quicke/Footer/Payments%20footer.png"
                alt="Logos - visa - Mastercard - Clave"
              />
            </div>
          </div>
        </footer>
        <div class="px-4 py-2 bg-pirOrange"></div>
      </section>
    </>
  );
};

export default Footer;
