import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ImageUploading from "react-images-uploading";

var imagen;
var type;

const Form_contact = (props) => {
  // const [nameCommerce, setNameCommerce] = useState("");
  // const [nameContact, setNameContact] = useState("");
  // const [ruc, setRuc] = useState("");
  // const [email, setEmail] = useState("");
  // const [dv, setDv] = useState("");
  // const [phone, setPhone] = useState("");
  // const [location, setLocation] = useState("");
  // const [comments, setComments] = useState("");

  // const handleSubmit = (e) => {
  //   const fields = [
  //     nameCommerce,
  //     nameContact,
  //     ruc,
  //     email,
  //     dv,
  //     phone,
  //     location,
  //     comments,
  //   ];

  //   e.preventDefault();
  //   var xhr = new XMLHttpRequest();
  //   var url = "https://api2.clau.io/mplat_forms/app/formulario";

  //   var data = [
  //     {
  //       email_destino: ["yanick@invupos.com"],
  //       mensaje: fields,
  //     },
  //   ];

  //   console.log(data);
  //   var final_data = JSON.stringify(data);

  //   xhr.open("POST", url);
  //   xhr.setRequestHeader("Content-Type", "application/json", "APPID", 150);

  //   xhr.onreadystatechange = function () {
  //     if (xhr.readyState === 4 && xhr.status === 200) {
  //       console.log(final_data);
  //     } else if (xhr.readyState === 4 && xhr.status === 403) {
  //       alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
  //     } else if (xhr.readyState === 4 && xhr.status === 404) {
  //       alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
  //     } else if (xhr.readyState === 4 && xhr.status === 400) {
  //       console.log("Error send email");
  //     }
  //   };

  //   xhr.send(final_data);
  //   clearForm();
  // };

  // const clearForm = () => {
  //   setNameCommerce("");
  //   setNameContact("");
  //   setRuc("");
  //   setEmail("");
  //   setDv("");
  //   setPhone("");
  //   setLocation("");
  //   setComments("");
  // };

  const [images, setImages] = useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);
    imagen = imageList[0].data_url.split(",")[1];
    type = imageList[0].data_url.split(";")[0].split("/")[1];
    if (type === "jpeg") {
      type = "jpg";
    }
    // console.log(type);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const ncommerce_field = event.target.namecommerce.value;
    const ncontact_field = event.target.namecontact.value;
    const ruc_field = event.target.ruc.value;
    const email_field = event.target.email.value;
    const dv_field = event.target.dv.value;
    const phone_field = event.target.phone.value;
    const location_field = event.target.location.value;
    const comments_field = event.target.comments.value;
    const domain_field = event.target.domain.value;
    // console.log(imagen);
    if (imagen == undefined || imagen == null) {
      toast.error("Debe agregar una imagen.", {
        position: "bottom-center",
      });
      return;
    }
    const data2 = {
      nombre: ncommerce_field.replace(/\s+/g, "") + "." + type,
      img: imagen,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        APPID: 150,
        apikey:
          "POS-wDbrHevJ5qCzPNGRUWie481ncDW3zbAzs6dMtPzsusdRW7koFtbLde9WGS",
        origen: "quickie",
      },
    };
    //Subir la imagen primero y luego el formulario
    axios
      .post(
        "https://apidev2.clau.io/ecommerce_api/v1/subir_imagen",
        data2,
        axiosConfig
      )
      .then((response) => {
        // console.log(response);
        if (response.data.CodRes == 0) {
          const mensaje = `
            Nombre del comercio: ${ncommerce_field} <br>
            Nombre de Contacto: ${ncontact_field} <br>
            Ruc: ${ruc_field} <br>
            Correo electrónico: ${email_field} <br>
            DV: ${dv_field} <br>
            Teléfono: ${phone_field} <br>
            Dirección: ${location_field} <br>
            Dominio: ${domain_field} <br>
            Cms: Quick-e <br>
            <br>
            Comentarios:
            ${comments_field} 
            <br>
            Imagen: ${response.data.ArrRes} <br>
        `;
          const data = {
            destino: [
              "esales@credicorpbank.com",
              "yeci@clau.io",
              "admin@clau.io",
            ],

            mensaje: mensaje,
            sujeto: "Ayuda en Quick-e",
            pass: "chimichanga",
            origen: "Landing Quick-e",
            razon: "Nuevo Cliente",
          };

          axios
            .post(
              "https://apidev2.clau.io/mplat_forms/app/open_mail",
              data,
              axiosConfig
            )
            .then((response) => {
              if (response.data.CodRes == 0) {
                // console.log(response);
                event.target.reset();
                toast.success("Email enviado correctamente.", {
                  position: "bottom-center",
                });
              } else {
                toast.error("Error al enviar el email.", {
                  position: "bottom-center",
                });
              }
              //TODO Esto hay que hacerlo
              const mensaje1 = `
                <style type="text/css">
                body,
                .background_main,
                p,
                table,
                td,
                div {
                  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
                }
          
                img {
                  border: none;
                  -ms-interpolation-mode: bicubic;
                  max-width: 100%;
                }
          
                p {
                  padding-bottom: 2px;
                }
          
                body {
                  background: #fff;
                  font-size: 17px;
                  line-height: 24px;
                  margin: 0;
                  padding: 0;
                  -ms-text-size-adjust: 100%;
                  -webkit-text-size-adjust: 100%;
                }
          
                table {
                  border-collapse: collapse;
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  width: 100%;
                }
          
                td {
                  font-size: 17px;
                  line-height: 24px;
                  vertical-align: top;
                }
          
                .email_footer td,
                .email_footer p,
                .email_footer span,
                .email_footer a {
                  font-size: 15px;
                  text-align: center;
                }
          
                .email_footer td {
                  padding-top: 20px;
                }
          
                h1,
                h2,
                h3,
                h4 {
                  color: #434245;
                  font-weight: 400;
                  line-height: 1.4;
                  margin: 0;
                  margin-bottom: 12px;
                }
          
                h1 {
                  font-size: 30px;
                  line-height: 36px;
                  font-weight: 900;
                  letter-spacing: -0.75px;
                  text-align: left;
                }
          
                p,
                ul,
                ol {
                  font-size: 17px;
                  line-height: 24px;
                  font-weight: normal;
                  margin: 0;
                  margin-bottom: 15px;
                }
          
                p li,
                ul li,
                ol li {
                  list-style-position: inside;
                  margin-left: 5px;
                }
          
                a {
                  color: #3498db;
                  text-decoration: none;
                }
          
                a:hover {
                  text-decoration: underline;
                }
          
                .button_link::after {
                  position: absolute;
                  content: "";
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  border-radius: 22px;
                }
          
                .button_link:hover::after {
                  box-shadow: inset 0 -2px #f58820;
                }
          
                .preview_text {
                  color: transparent;
                  display: none;
                  height: 0;
                  max-height: 0;
                  max-width: 0;
                  opacity: 0;
                  overflow: hidden;
                  mso-hide: all;
                  visibility: hidden;
                  width: 0;
                  font-size: 1px;
                  line-height: 1px;
                }
          
                .preview_text a {
                  color: #3aa3e3 !important;
                  font-weight: bold;
                }
          
                @media only screen and (max-width: 600px) {
                  table[class="background_main"] .sm_full_width {
                    width: 100% !important;
                  }
                  table[class="background_main"] .sm_align_center {
                    text-align: center !important;
                  }
                  table[class="background_main"] .sm_auto_width {
                    width: auto !important;
                  }
                  table[class="background_main"] .sm_auto_height {
                    height: auto !important;
                  }
          
                  table[class="background_main"] .sm_border_box {
                    box-sizing: border-box !important;
                  }
          
                  table[class="background_main"] .sm_block {
                    display: block !important;
                  }
                  table[class="background_main"] .sm_inline_block {
                    display: inline-block !important;
                  }
                  table[class="background_main"] .sm_table {
                    display: table !important;
                  }
          
                  table[class="background_main"] .sm_no_side_padding {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                  }
                  table[class="background_main"] .sm_no_border_radius {
                    border-radius: 0 !important;
                  }
                  table[class="background_main"] .sm_no_padding {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                  }
                  table[class="background_main"] .sm_os_icons_height {

                    height: 44px;
                  }
                  .social_img_bottom_margin {
          
                    margin-bottom: 20px !important;
                  }
                  .social_p_bottom_margin {
          
                    margin-bottom: 40px !important;
                  }
                }
          
                @media all {
                  .ExternalClass {
                    width: 100%;
                  }
                  .ExternalClass,
                  .ExternalClass p,
                  .ExternalClass span,
                  .ExternalClass font,
                  .ExternalClass td,
                  .ExternalClass div {
                    line-height: 100%;
                  }
                  .email_footer a {
                    color: #434245 !important;
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    text-decoration: none !important;
                  }
                }
          
                a:hover {
                  text-decoration: underline !important;
                }
              </style>
            </head>
          
            <body>
              <table
                width="100%"
                cellpadding="0"
                cellspacing="0"
                border="0"
                class="background_main"
                style="
                  background-color: #ffffff;
                  padding-top: 20px;
                  color: #434245;
                  width: 100%;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                "
              >
                <tr>
                  <td
                    valign="top"
                    class="sm_full_width"
                    style="margin: 0 auto; width: 100%; max-width: 600px; display: block;"
                  >
                    <div
                      class="sm_no_padding"
                      style="
                        margin: 0 auto;
                        padding: 30px 0 40px;
                        display: block;
                        box-sizing: border-box;
                      "
                    >
                      <table
                        style="width: 100%; color: #434245;"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <td style="box-sizing: border-box;">
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tr>
                                <td>
                                  <img style=" margin: 0 0 20px 0; padding-right: 20px;
                                  padding-left: 20px;"
                                  src=https://img.invupos.com/quicke/Contact%20form%20/Logos%20/quickelogo.png
                                  />
          
                                  <h1
                                    style="
                                      font-size: 30px;
                                      padding-right: 30px;
                                      padding-left: 30px;
                                    "
                                  >
                                    Gracias por preferirnos
                                  </h1>
          
                                  <p
                                    style="
                                      font-size: 17px;
                                      padding-right: 30px;
                                      padding-left: 30px;
                                    "
                                  >
                                    Bienvenido a Quick-e,
                                  </p>
          
                                  <p
                                    style="
                                      font-size: 17px;
                                      padding-right: 30px;
                                      padding-left: 30px;
                                    "
                                  >
                                    Las credenciales de acceso serán enviadas en las
                                    próximas 24 horas.
                                  </p>
                                  <p
                                    style="
                                      font-size: 17px;
                                      padding-right: 30px;
                                      padding-left: 30px;
                                    "
                                  >
                                    Para más información:
                                  </p>
          
                                  <div style="padding-right: 30px; padding-left: 30px;">
                                    <table style="width: 100%;" class="sm_table">
                                      <tr style="width: 100%;">
                                        <td style="width: 100%;">
                                          <span
                                            style="
                                              display: inline-block;
                                              position: relative;
                                              border-radius: 4px;
                                            "
                                            class="sm_full_width"
                                          >
                                            <a
                                              class="button_link sm_full_width sm_border_box"
                                              target="_blank"
                                              href="https://www.credicorpbank.com/banca-corporativa-comercial/e-commerce/"
                                              style="
                                                min-width: 196px;
                                                border-top: 13px solid;
                                                border-bottom: 13px solid;
                                                border-right: 24px solid;
                                                border-left: 24px solid;
                                                border-color: #36b04a;
                                                border-radius: 22px;
                                                background-color: #36b04a;
                                                color: #ffffff;
                                                font-size: 18px;
                                                line-height: 18px;
                                                word-break: break-word;
                                                display: inline-block;
                                                text-align: center;
                                                font-weight: 900;
                                                text-decoration: none !important;
                                              "
                                            >
                                              Cliquea aquí
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    class="email_footer"
                    style="
                      padding: 0 30px 40px;
                      border-top: 1px solid #e1e1e4;
                      line-height: 24px;
                      font-size: 15px;
                      color: #717274;
                      text-align: center;
                      width: 100%;
                    "
                  >
                    <table
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      align="center"
                      style="margin-top: 20px; background-color: white;"
                    >
                      <tr>
                        <td>&nbsp;</td>
                        <td>
                          <span style="display: block;">
                            Powered by
                            <a
                              href="https://www.credicorpbank.com/"
                              target="_blank"
                              style="text-decoration: none; color: #434245;"
                              >Credicorp Bank.</a
                            >
                          </span>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </body>
              `;
              const data1 = {
                destino: [email_field],
                mensaje: mensaje1,
                sujeto: "Bienvenido Quick-e",
                pass: "chimichanga",
                origen: "Landing Quick-e",
                razon: "Nuevo Cliente",
              };
              axios
                .post(
                  "https://apidev2.clau.io/mplat_forms/app/open_mail",
                  data1,
                  axiosConfig
                )
                .then((response) => {
                  console.log(response);
                  if (response.data.CodRes == 0) {
                    console.log(response.data);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error("Nombre de comercio ya existe.", {
            position: "bottom-center",
          });
          return;
        }
        // event.target.reset();
        // toast.success("Email enviado correctamente.", {
        //   position: "bottom-center",
        // });
      })
      .catch((error) => {
        console.log(error);
      });

    const fields = {
      ncommerce_field,
      ncontact_field,
      ruc_field,
      email_field,
      dv_field,
      phone_field,
      location_field,
      comments_field,
      domain_field,
    };
  };

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <section className="bg-[#148040] bg-[url('https://img.invupos.com/quicke/Contact%20form%20/shape/Contact%20shape.png')] bg-[length:600px] bg-[mr-16]  lg:bg-[right_bottom_-5rem]  bg-[right_bottom_15rem] bg-no-repeat">
        <div className="max-w-screen-xl  px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="flex mb-16 justify-center text-teal-600">
            <div>
              <img
                src="https://img.invupos.com/quicke/Logo_clau_new1.svg"
                alt="Clau.io logo"
                class="block object-contain h-[69px]"
              />
            </div>
            <div class="divider mx-4 border-[2.5px] border-l border-pirWhite"></div>
            <div>
              <img
                src="https://img.invupos.com/quicke/Quick_logo_white.svg"
                alt="Quick-e logo"
                class="block object-contain h-16"
              />
            </div>
          </div>
          <p className="font-semibold text-left text-4xl font-avenirm tracking-tight lg:text-[40px] md:text-[40px] text-pirWhite">
            Solicitud en Línea
          </p>
          <p className="text-pirWhite text-left mt-4">
            Complete el formulario y nos pondremos en contacto con usted.
          </p>
          <form
            className="mt-10"
            name="sentMessage"
            id="contactForm"
            onSubmit={submitHandler}
          >
            <div class="grid md:grid-cols-2 md:gap-6 ">
              <div class="relative z-0 mb-6 w-full group">
                <input
                  type="text"
                  class="block py-3.5 px-6 w-full rounded-full  text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Nombre del Comercio"
                  required
                  id="namecommerce"
                  name="namecommerce"
                  // name="namecommerce"
                  // value={nameCommerce}
                  // onChange={(e) => setNameCommerce(e.target.value)}
                />
              </div>
              <div class="relative z-0 mb-6 w-full group ">
                <input
                  type="text"
                  class="block py-3.5  rounded-full px-6 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Nombre del contacto"
                  required
                  id="namecontact"
                  name="namecontact"
                  // name="namecontact"
                  // value={nameContact}
                  // onChange={(e) => setNameContact(e.target.value)}
                />
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6 ">
              <div class="relative z-0 mb-6 w-full group">
                <input
                  type="text"
                  class="block py-3.5 px-6 rounded-full w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="RUC"
                  required
                  id="ruc"
                  name="ruc"
                  // name="ruc"
                  // value={ruc}
                  // onChange={(e) => setRuc(e.target.value)}
                />
              </div>
              <div class="relative z-0 mb-6 w-full group">
                <input
                  type="text"
                  class="block py-3.5 px-6 rounded-full w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Correo Electrónico"
                  required
                  id="email"
                  name="email"
                  // name="email"
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 mb-6 w-full group">
                <input
                  type="text"
                  class="block py-3.5 px-6 rounded-full w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="DV"
                  required
                  id="dv"
                  name="dv"
                  // value={dv}
                  // onChange={(e) => setDv(e.target.value)}
                />
              </div>
              <div class="relative z-0 mb-6 w-full group">
                <input
                  type="text"
                  class="block py-3.5 px-6 rounded-full w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Teléfono"
                  required
                  id="phone"
                  name="phone"
                  // value={phone}
                  // onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6 ">
              <div class="relative z-0 mb-6 w-full group">
                <input
                  class="block py-3.5 px-6 rounded-full w-full text-sm text-gray-900 bg-transparent border-0 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Dirección"
                  required
                  id="location"
                  name="location"
                  // value={location}
                  // onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div class="relative z-0 mb-6 w-full group">
                <input
                  class="block py-3.5 px-6 rounded-full w-full text-sm text-gray-900 bg-transparent border-0 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Dominio (opcional)"
                  id="domain"
                  name="domain"
                />
              </div>
            </div>
            <div class="grid md:grid-cols-1 md:gap-6 ">
              <div class="relative z-0 mb-6 w-full group">
                <div>
                  <label class="block text-base font-semibold mb-4 text-pirWhite">
                    Sube tu logo aquí
                  </label>
                  <div class="mt-1 flex justify-center rounded-md border-2 border-dashed border-pirWhite px-6 pt-5 pb-6">
                    <div class="space-y-1 text-center">
                      <svg
                        class="mx-auto h-12 w-12 text-pirWhite"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div class=" text-sm text-center text-pirWhite">
                        {/* <label
                          for="file-upload"
                          class="relative cursor-pointer rounded-md bg-white font-bold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            class="sr-only"
                          />
                        </label> */}
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          resolutionType={"less"}
                          resolutionHeight={2000}
                          resolutionWidth={2000}
                          maxFileSize={2097152}
                          required
                          type="file"
                          dataURLKey="data_url"
                          acceptType={["jpg", "png"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                            errors,
                            capture,
                          }) => (
                            <div className="upload__image-wrapper mb-6">
                              <button
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <span className="text-base font-bold">
                                  Upload a file or Drop here
                                </span>
                              </button>
                              &nbsp;
                              {imageList.map((image, index) => (
                                <div key={index} className="mt-6">
                                  <img
                                    src={image.data_url}
                                    width="250"
                                    className="rounded-xl"
                                  />
                                  <div className="space-x-2 mt-4">
                                    <button
                                      onClick={() => onImageUpdate(index)}
                                      class="inline-flex items-center rounded-full border-2 border-[#171515] bg-[#171515] px-5 py-2 text-sm font-medium text-white "
                                    >
                                      Update
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="ml-2 h-5 w-5"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                        />
                                      </svg>
                                    </button>
                                    <button
                                      onClick={() => onImageRemove(index)}
                                      class="inline-flex items-center rounded-full border-2 border-[#171515] bg-[#171515] px-5 py-2 text-sm font-medium text-white"
                                    >
                                      Remove
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="ml-2 h-5 w-5"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              ))}
                              {errors && (
                                <div style={{ color: "red" }}>
                                  {errors.maxNumber && (
                                    <span>
                                      Number of selected images exceed maxNumber
                                    </span>
                                  )}
                                  {errors.acceptType && (
                                    <span>
                                      Your selected file type is not allow
                                    </span>
                                  )}
                                  {errors.maxFileSize && (
                                    <span>
                                      Selected file size exceed maxFileSize
                                    </span>
                                  )}
                                  {errors.resolution && (
                                    <span>
                                      Selected file is not match your desired
                                      resolution
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                      <p class="text-[12px] text-pirWhite max-w-[250px] font-semibold">
                        PNG, JPG, up to 2MB | Maximum supported resolution:
                        2000px x 2000px
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-left mb-8">
              <p className="mt-4 text-pirWhite">
                Comentarios o aclaraciones adicionales relativos a su negocio:
              </p>
            </div>
            <div class="grid md:grid-cols-1 md:gap-6">
              <div class="relative z-0 mb-6 w-full group">
                <textarea
                  type="text"
                  class="block py-3.5 px-6 w-full rounded-2xl text-sm text-gray-900 bg-transparent  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Escribir Aquí"
                  required
                  id="comments"
                  name="comments"
                  // value={comments}
                  // onChange={(e) => setComments(e.target.value)}
                />
              </div>
            </div>
            <div className="text-left">
              <p className="italic text-pirWhite">
                Por este medio, el comercio (i) asegura que la información
                proporcionada por. La empresa para el acuerdo de servicios de
                comercio electrónico con el Banco es veraz, completa y no
                engañosa: (ii) reconoce que el comercio ha leído y entiende este
                acuerdo y los procedimientos operativos y consiente en aceptar
                los términos: y (iii) autoriza a el Banco y sus agentes para
                acreditar y debitar la cuenta según este acuerdo y asegura y
                garantiza al Banco que los firmantes de este acuerdo son los
                autorizados para tales fines y para actuar en nombre del
                comercio en el desempeño del mismo. La aplicación al servicio
                ofrecido por el Banco no es garantía de su aceptación.
              </p>
            </div>

            <div class="flex items-center mt-8">
              <input
                type="checkbox"
                required
                class="w-5 h-5 border-gray-300 rounded"
              />

              <p class="ml-3 text-base font-medium text-pirWhite">
                Aceptación en las{" "}
                <span>
                  <a
                    href="https://cdn.clau.io/terms/quickie/politicas-quickie.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative font-bold text-pirWhite before:absolute before:-bottom-1 before:h-0.5 before:w-full before:scale-x-0 before:bg-pirWhite before:transition hover:before:scale-x-100"
                  >
                    políticas
                  </a>
                </span>
                ,{" "}
                <span>
                  <a
                    href="https://cdn.clau.io/terms/quickie/TerminosdeUso-quickie.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative font-bold text-pirWhite before:absolute before:-bottom-1 before:h-0.5 before:w-full before:scale-x-0 before:bg-pirWhite before:transition hover:before:scale-x-100"
                  >
                    términos y condiciones.
                  </a>
                </span>
              </p>
            </div>
            <div className="mt-2">
              <button
                type="submit"
                id="sendMessageButton"
                class="inline-block px-10 mt-5 py-5 text-sm font-medium text-white bg-pirOrange text-pirWhite font-semibold  rounded-full active:text-indigo-500 hover:bg-[#ED811A] hover:text-indigo-600 focus:outline-none focus:ring"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
        <div class="w-40 h-40 absolute bg-[#36B04A] rounded-full top-4 right-12 hidden md:hidden lg:block"></div>
      </section>
    </>
  );
};

export default Form_contact;
