import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const features = [
  {
    id: 1,
    name: "Vende a quien quieras desde donde sea 24/7",
    imageSrc:
      "https://img.invupos.com/quicke/Features/icons%20features%20/Icon%20feature%201.svg",
  },
  {
    id: 2,
    name: "Recibe pagos diarios de tus ventas",
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Features/icons%20features%20/Icon%20feature%202.svg",
  },
  {
    id: 3,
    name: "Tendrás respaldo de Credicorp Bank",
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Features/icons%20features%20/Icon%20feature%203.svg",
  },
  {
    id: 4,
    name: "Plataforma segura, confiable y fácil de personalizar",
    imageSrc:
      "https://img.invupos.com/quicke/Features/icons%20features%20/Icon%20feature%201.svg",
  },
  {
    id: 5,
    name: "Atención y capacitación de nuestro equipo confiable.",
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Features/icons%20features%20/Icon%20feature%202.svg",
  },
  {
    id: 6,
    name: "Sistema de reportería.",
    href: "#",
    imageSrc:
      "https://img.invupos.com/quicke/Features/icons%20features%20/Icon%20feature%203.svg",
  },
];

const Features = () => {
  return (
    <div className="ColorContainer">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 overflow-hidden  bg-[url('https://img.invupos.com/quicke/Features/shape/shape%20features.png')] bg-[length:260px] bg-[mr-16] bg-[left_top_-15rem] bg-no-repeat">
        <div className="mt-16 grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-28">
          {features.map((feature) => (
            <div key={feature.id} className="group relative">
              <div class="p-8 bg-pirWhite rounded-xl h-72">
                <div class="flex items-center">
                  <img
                    src={feature.imageSrc}
                    alt={feature.name}
                    class="w-20 object-container h-20 rounded-full"
                  />
                </div>
                <ul class="mt-5 text-left">
                  <h2 class="mt-3 font-avenird text-[18px] lg:max-w-[250px] tracking-tight  sm:text-[32px] sm:leading-none text-[#313C42]">
                    {feature.name}
                  </h2>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
